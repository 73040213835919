/* You can add global styles to this file, and also import other style files */
@import './variables'; // Path to the _variables.scss file
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


$primary: $primary;
@import "bootstrap/scss/bootstrap";


.bg-dark {
    background-color: $primary !important;
}

.bg-secondary {
    background-color: $secondary !important;
}

h1 {
    text-align: center;
    font-family: 'AmsterdamThree', cursive;
    font-size: 2em;
    color: $primary;
  }

  h2 {
    text-align: center;
    font-family: 'AmsterdamThree', cursive;
    font-size: 3em;
    color: $primary;
  }

  h3 {
    font-family: 'AmsterdamThree', cursive;
    font-size: 2.5em;
    color: $tercero;
  }

  .separator-100 {
    margin-bottom: 100px;
  }
  .separator-30{
    margin-bottom: 30px;
  }
  .separator-20 {
    margin-bottom: 20px;
  }
  .separator-15 {
    margin-bottom: 15px;
  }
  .separator-10 {
    margin-bottom: 10px;
  }
  .separator-5 {
    margin-bottom: 5px;
  }
  .card {
    /* Your styles here */
    background-color: #fff;
    border: none;
    box-shadow: none;
  }

  p {
    font-family: 'Montserrat', sans-serif;
  }
